import Element from '../ANTICore/Element';
import Overlay from '../ANTICore/Overlay';

// General singleton class.
export default class TheHeader extends Element {
  static #instance;

  constructor() {
    super(document.querySelector('#TheHeader'));

    // Handle signleton
    if (TheHeader.#instance) {
      return TheHeader.#instance;
    }

    TheHeader.#instance = this;

    this.#init();
  }

  #init() {
    this.navigation = new Overlay(document.querySelector('#TheHeaderNavigation'));

    const mobileMenuV2 = document.querySelectorAll('.menu-item.has-submenu');

    if (mobileMenuV2) {
      mobileMenuV2.forEach((el) => {
        el.addEventListener('click', (evt) => {
          el.classList.toggle('open');
        });
      });
    }
  }

  static getInstance() {
    if (TheHeader.#instance) {
      return TheHeader.#instance;
    }

    TheHeader.#instance = new TheHeader(document.querySelector('#TheHeader'));
    return TheHeader.#instance;
  }
}
