export default class UpcomingEvents {
    constructor(element) {
        this.element = element;
        this.desktopFilterOptionsButton = element.querySelector('.filter-options-button');
        this.desktopOptionsList = element.querySelector('.dropdown');
        this.desktopFilterButton = element.querySelector('.filter-button');
        this.radioButtons = this.desktopOptionsList.querySelectorAll('input[name="eventCategoryOptions"]');
        this.upcomingEvents = element.querySelector('.UpcomingEvents__grid');
        // this.viewMoreButton = element.querySelector('.UpcomingEvents__view-more');
        this.init();
    }

    init() {
        // const urlParams = new URLSearchParams(window.location.search);
        // const categoryParam = urlParams.get('category');

        // console.log('categoryParam', categoryParam);

        // if (categoryParam) this.element.scrollIntoView();



        console.log('init', this.desktopFilterOptionsButton, this.desktopOptionsList);

        this.desktopFilterOptionsButton.addEventListener('click', this.toggleFilterOptions);
        this.desktopFilterButton.addEventListener('click', this.filterEvents);
        // this.viewMoreButton.addEventListener('click', this.viewMoreEvents);
    }

    toggleFilterOptions = (evt) => {
        console.log('toggleFilterOptions', this.desktopFilterButton, this.desktopOptionsList);

        evt.preventDefault();
        this.desktopOptionsList.classList.toggle('dropdown--active');
        this.desktopFilterOptionsButton.classList.toggle('filter-options-button--active');
    }

    filterEvents = (evt) => {
        console.log('filterEvents');

        evt.preventDefault();
        this.desktopOptionsList.classList.remove('dropdown--active');
        this.desktopFilterOptionsButton.classList.remove('filter-options-button--active');

        let selectedOption;
        let selectedOptionLabel;
        for (const radioButton of this.radioButtons) {
            if (radioButton.checked) {
                console.log(radioButton, radioButton.id)

                selectedOption = radioButton.value;
                selectedOptionLabel = this.desktopOptionsList.querySelector(`label[for="${radioButton.id}"]`).textContent;
                break;
            }
        }
        console.log('selectedOption', selectedOption, selectedOptionLabel);

        if (selectedOption) {
            this.upcomingEvents.querySelectorAll('[data-categories]').forEach((elem) => {
                console.log('elem categories', elem.dataset.categories);

                if (selectedOption === '*') {
                    elem.classList.remove('hidden');
                } else {
                    JSON.parse(elem.dataset.categories).includes(selectedOption)
                        ? elem.classList.remove('hidden')
                        : elem.classList.add('hidden');
                }
            });

            console.log(this.desktopFilterOptionsButton.querySelector('[data-target="button-label"]'))
            this.desktopFilterOptionsButton.querySelector('[data-target="button-label"]').textContent = selectedOptionLabel;
            // window.location.href = `/?category=${selectedOption}`;
        }
    }

    // viewMoreEvents = (evt) => {
    //     console.log('viewMoreEvents');

    //     evt.preventDefault();
        
    // }
}
