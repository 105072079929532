//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";
// import './ANTICore/browserExtensions';

//
// Bundle fonts
//
import '@/fonts/Agipo/Agipo-Regular.woff2';
import '@/fonts/Agipo/Agipo-Regular.woff';
import '@/fonts/Agipo/Agipo-Italic.woff2';
import '@/fonts/Agipo/Agipo-Italic.woff';
import '@/fonts/Agipo/Agipo-Bold.woff2';
import '@/fonts/Agipo/Agipo-Bold.woff';
// import './main.scss'

import { registerContainerLink } from '@/util/registerContainerLink';
import TestComponent from './components/TestComponent';
import TheHeader from './parts/TheHeader';

import Viewport from '@/ANTICore/Viewport';
import TextSplit from '@/ANTICore/TextSplit';
import Accordion from '@/components/Accordion';
import Dropdown from '@/components/Dropdown';
import DropdownSelect from '@/components/DropdownSelect';
import OverlayDrawer from '@/components/OverlayDrawer';
import PageNavigation from '@/components/PageNavigation';
import Marquee from '@/components/Marquee';
import Lightbox from '@/components/Modal';
// import Form from '@/components/Form';
import UpcomingEvents from '@/components/UpcomingEvents';

//
// Application
//
const ProjectName = {
  // Options / globals
  body: null,

  init() {
    this.body = document.body;
    this.TheHeader = TheHeader.getInstance();

    let el = this.body.querySelector('#PageNavigation');
    if(el) {
      const pageNavigation = new PageNavigation(el);

      [...this.body.querySelectorAll('[data-current-section]')].map(el => {
        pageNavigation.events.subscribe(PageNavigation.EVENT_CHANGE, (evt) => {
          el.innerHTML = evt.title;
        });
      });
    }

    [...this.body.querySelectorAll('.Accordion__body')].forEach(el => {
      new Accordion(el);
    });

    [...this.body.querySelectorAll('.Dropdown__body')].forEach(el => {
      new Dropdown(el);
    });

    [...this.body.querySelectorAll('.DropdownSelect__body')].forEach(el => {
      new DropdownSelect(el);
    });

    [...this.body.querySelectorAll('[data-text-split]')].forEach(el => {
      new TextSplit(el);
    });

    // [...this.body.querySelectorAll('.Form')].forEach(el => {
    //   new Form(el);
    // });

    [...document.querySelectorAll('[data-container-link]')]
      .forEach(
        el => registerContainerLink(el, el.dataset.containerLink)
      );

    [...this.body.querySelectorAll('[data-in-view]')].forEach(el => {
      const unsubscribe = Viewport.inViewport(el, (target, inView) => {
        if(inView) {
          target.classList.add('in-view');
          target.classList.add('been-in-view');

          // Only run once.
          // unsubscribe();
        } else {
          target.classList.remove('in-view');
        }
      });
    });

    [...this.body.querySelectorAll('[data-in-center]')].forEach(el => {
      const unsubscribe = Viewport.inCenter(el, (target, inView) => {
        if(inView) {
          target.classList.add('in-center');
          target.classList.add('been-in-center');
        } else {
          target.classList.remove('in-center');
        }
      });
    });

    [...this.body.querySelectorAll('.Marquee')].forEach(el => new Marquee(el, {
      speed: el.dataset.speed || 1,
      reverse: el.dataset.reverse !== undefined,
      optional: el.dataset.optional !== undefined,
    }));

    [...this.body.querySelectorAll('.OverlayDrawer')].forEach(el => {
      new OverlayDrawer(el);
    });

    [...this.body.querySelectorAll('.VideoAutoplay')].forEach(el => {
      const video = el.querySelector('video');
      const image = el.querySelector('img');
      video.addEventListener('play', () => {
        image.style.display = 'none';
      })
    });

    // Update basket count when adding new products 
    const basketCount = this.body.querySelector('#snipcart-item-count');
    if(basketCount) {
      [...this.body.querySelectorAll('.snipcart-add-item')].forEach(el => {
        el.addEventListener('click', () => {
          basketCount.innerText = parseInt(basketCount.innerText) + 1;
        });
      });
    }

    const theAnnouncement = this.body.querySelector('#TheAcknowledgement');
    if(theAnnouncement) {
      setTimeout(() => theAnnouncement.classList.add('TheAcknowledgement--active'), 1000);      
    }

    const upcomingEvents = this.body.querySelector('.UpcomingEvents');
    if(upcomingEvents) {
      new UpcomingEvents(upcomingEvents);
    }

    // const test = {
    //   x: 0,
    //   y: 30
    // }

    // tween(test, {x: 1, y: 78}, 3000, 'quintInOut', 2000)
    //   .onUpdate(() => {
    //     console.log(test);
    //   })
    //   .onComplete(() => {
    //     console.log('complete', test);
    //   });

    // const test = new TestComponent();
    // setTimeout(() => test.destroy(), 3000);
  },

  initOnLoad() {
    this.body.classList.remove('preload');
  }
};


document.addEventListener('DOMContentLoaded', () => ProjectName.init());
window.addEventListener('load', () => ProjectName.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
